import { BrandButton } from "../src/app/components/shared"
import { Box, Flex, Text } from "@chakra-ui/react"

export default function ErrorPage() {
  return (
    <Box
      backgroundImage="url('/images/about-us-bg.png')"
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      position="relative"
      height="80vh"
      width="100vw"
    >
      <Flex
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        textAlign="center"
        backgroundColor="rgba(255,255,255,0.6)"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Text fontSize="4xl" mb={10}>
          Oops! Looks like you found a broken link.
        </Text>
        <BrandButton href="/">RETURN HOME</BrandButton>
      </Flex>
    </Box>
  )
}
